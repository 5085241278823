import type { Metadata } from "next";

import type { ComponentSharedSeo } from "@Shared/api";

import { getImageSrcFromApiData } from "./image-src";

export const getSeoFromApiData = (seoData?: ComponentSharedSeo | null): Metadata => {
    const seoObj: Metadata = {};

    if (seoData?.metaTitle) {
        seoObj.title = seoData.metaTitle;
    }

    if (seoData?.metaDescription) {
        seoObj.description = seoData.metaDescription;
    }

    if (seoData?.keywords) {
        seoObj.keywords = seoData.keywords;
    }

    if (seoData?.metaRobots) {
        seoObj.robots = seoData.metaRobots;
    }

    if (seoData?.metaImage?.data) {
        seoObj.openGraph = {
            images: getImageSrcFromApiData(seoData.metaImage.data?.attributes),
        };
    }

    if (seoData?.canonicalURL) {
        seoObj.alternates = {
            canonical: seoData.canonicalURL,
        };
    }

    return seoObj;
};
