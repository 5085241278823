const phoneRx = /(\+7|7|8)([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/;

export const phoneNormalize = (phone: string) => {
    return phone && String(phone).replaceAll(/[^0-9+]/g, "");
};

export const phonePrint = (phone: string | null) => {
    const m = phone?.match(phoneRx);

    if (m && m?.length > 0) {
        return `${m[1].startsWith("7") ? "+" : ""}${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
    }

    return phone;
};
