import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionSocial, ResponseCollection, ResponseData } from "../types";

export class SocialsApi extends Repository {
    getSocialsData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionSocial>> = await this.client.request.get(
            ENDPOINTS.socials,
            {
                sort: ["sort:asc", "id:desc"],
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
