import urlJoin from "url-join";

import type { ResponseImage } from "@Shared/api";
import { UPLOADS_URL } from "@Shared/config";

export const getImageSrcFromApiData = (image?: ResponseImage): string | undefined => {
    if (!image) {
        return;
    }

    return image.url.startsWith("/") ? urlJoin(UPLOADS_URL, image.url) : image.url;
};
