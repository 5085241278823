import AngleDownSm from "./images/angle-down-sm.svg";
import AngleRight from "./images/angle-right.svg";
import AngleTopSm from "./images/angle-top-sm.svg";
import ArrowLeft from "./images/arrow-left.svg";
import ArrowRight from "./images/arrow-right.svg";
import ArrowTopRight from "./images/arrow-top-right.svg";
import Behance from "./images/behance.svg";
import CloseCircle from "./images/close-circle.svg";
import Close from "./images/close.svg";
import Globe from "./images/globe.svg";
import Image from "./images/image.svg";
import Instagram from "./images/instagram.svg";
import Lightning from "./images/lightning.svg";
import LinkedIn from "./images/linkedin.svg";
import MenuClose from "./images/menu-close.svg";
import Menu from "./images/menu.svg";
import RhombusRounded from "./images/rhombus-rounded.svg";
import Telegram from "./images/telegram.svg";

const iconsList = {
    angleDownSm: AngleDownSm,
    angleRight: AngleRight,
    angleTopSm: AngleTopSm,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    arrowTopRight: ArrowTopRight,
    behance: Behance,
    closeCircle: CloseCircle,
    close: Close,
    globe: Globe,
    image: Image,
    instagram: Instagram,
    lightning: Lightning,
    linkedIn: LinkedIn,
    menuClose: MenuClose,
    menu: Menu,
    rhombusRounded: RhombusRounded,
    telegram: Telegram,
} as const;

export const IconsCollection = {
    ...iconsList,
} as const;

export type IconsKeys = keyof typeof IconsCollection;
