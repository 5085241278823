export const PAGES = {
    home: "/",
    projects: "/projects",
    project: (slug: string) => `/project/${slug}`,
    projectsCategory: (slug: string) => `/projects/${slug}`,
    about: "/about",
    blog: "/blog",
    article: (slug: string) => `/blog/${slug}`,
    contact: "/contact",
    privacyPolicy: "/privacy-policy",
};
