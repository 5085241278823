import { FC } from "react";

import { ElProps } from "@Shared/types";
import { Image, ImageProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type BgImageCoverPropsClassNames = {
    image?: string;
    imageDevices?: string;
};

export type BgImageCoverProps = ElProps<"div"> &
    Pick<ImageProps, "alt" | "sizes" | "priority"> & {
        classNames?: BgImageCoverPropsClassNames;
        src: ImageProps["src"];
        srcDevices?: ImageProps["src"];
    };

export const BgImageCover: FC<BgImageCoverProps> = ({
    className,
    classNames,
    src,
    srcDevices,
    alt,
    sizes,
    priority,
    ...restProps
}) => {
    const imagesCommonClassName = "absolute inset-0 size-full object-cover";
    const imagesCommonProps = {
        alt,
        sizes,
        fill: true,
        priority,
    };

    return (
        <div
            className={twcx("pointer-events-none absolute inset-0 -z-10 select-none overflow-hidden", className)}
            aria-hidden
            {...restProps}
        >
            <Image
                className={twcx(imagesCommonClassName, { "max-md:hidden": srcDevices }, classNames?.image)}
                src={src}
                {...imagesCommonProps}
            />

            {srcDevices && (
                <Image
                    className={twcx(imagesCommonClassName, "md:hidden", classNames?.imageDevices)}
                    src={srcDevices}
                    {...imagesCommonProps}
                />
            )}
        </div>
    );
};
