import { getPopulateFields } from "@Shared/utils";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import {
    CollectionPageSimple,
    Response,
    ResponseCollection,
    ResponseData,
    SinglePageArticle,
    SinglePageArticles,
    SinglePageHome,
    SinglePageNotFound,
    SinglePageProject,
    SinglePageProjects,
} from "../types";

export class PagesApi extends Repository {
    getPageArticleData = async () => {
        const result: Response<ResponseData<SinglePageArticle>> = await this.client.request.get(ENDPOINTS.pageArticle, {
            populate: "sections",
        });

        return result?.data?.attributes ?? null;
    };

    getPageArticlesData = async () => {
        const result: Response<ResponseData<SinglePageArticles>> = await this.client.request.get(
            ENDPOINTS.pageArticles,
            { populate: getPopulateFields(["seo"]) }
        );

        return result?.data?.attributes ?? null;
    };

    getPageHomeData = async (): Promise<SinglePageHome> => {
        const result: Response<ResponseData<SinglePageHome>> = await this.client.request.get(ENDPOINTS.pageHome, {
            populate: `${getPopulateFields(["seo", ["video", "sections.video"], ["video", "sections.videoDevices"]])},sections,sections.image,sections.imageDevices,sections.cards,sections.cards.image,sections.cards.imageDevices,sections.goal,sections.reviews`,
        });

        return result?.data?.attributes ?? null;
    };

    getPageNotFoundData = async () => {
        const result: Response<ResponseData<SinglePageNotFound>> = await this.client.request.get(
            ENDPOINTS.pageNotFound,
            { populate: "button" }
        );

        return result?.data?.attributes ?? null;
    };

    getPageProjectData = async () => {
        const result: Response<ResponseData<SinglePageProject>> = await this.client.request.get(ENDPOINTS.pageProject, {
            populate: "sections",
        });

        return result?.data?.attributes ?? null;
    };

    getPageProjectsData = async () => {
        const result: Response<ResponseData<SinglePageProjects>> = await this.client.request.get(
            ENDPOINTS.pageProjects,
            { populate: getPopulateFields(["seo"]) }
        );

        return result?.data?.attributes ?? null;
    };

    getPageSimpleData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionPageSimple>> = await this.client.request.get(
            ENDPOINTS.pagesSimple,
            {
                filters: {
                    slug,
                },
                populate: getPopulateFields(["seo"]),
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };

    getAllPagesSimpleData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionPageSimple>> = await this.client.request.get(
            ENDPOINTS.pagesSimple,
            {
                fields: ["slug"],
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
