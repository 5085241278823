import { getPopulateFields } from "@Shared/utils";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import {
    CollectionProject,
    CollectionProjectCategory,
    CollectionProjectPreview,
    ResponseCollection,
    ResponseData,
} from "../types";

export class ProjectsApi extends Repository {
    getProjectsData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionProjectPreview>> = await this.client.request.get(
            ENDPOINTS.projects,
            {
                sort: ["sort:asc", "id:desc"],
                fields: [
                    "slug",
                    "sort",
                    "name",
                    "description",
                    "data",
                    "badges",
                    "externalLink",
                    "useExternalLinkForPreview",
                ],
                populate: "imagePreview,imagePreviewWide,imagePreviewDevices",
                ...params,
            }
        );

        return {
            data: result?.data ?? [],
            pagination: result?.meta?.pagination ?? null,
        };
    };

    getProjectData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionProject>> = await this.client.request.get(
            ENDPOINTS.projects,
            {
                filters: {
                    slug,
                },
                populate: `${getPopulateFields(["seo", ["video", "sections.video"], ["video", "sections.videoDevices"]])},sections,sections.image,sections.imageDevices,sections.link`,
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };

    getProjectsCategoriesData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionProjectCategory>> = await this.client.request.get(
            ENDPOINTS.projectsCategories,
            {
                sort: ["sort:asc", "id:desc"],
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
