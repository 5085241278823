import { forwardRef } from "react";

import { BgImageCover, BgImageCoverProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { Container, ContainerProps } from "./Container";

export type ContainerSectionProps = ContainerProps & {
    bgImageCover?: BgImageCoverProps;
};

export const ContainerSection = forwardRef<HTMLDivElement, ContainerSectionProps>(
    ({ className, bgImageCover, children, ...restProps }, ref) => (
        <Container
            ref={ref}
            className={twcx(
                "relative z-0 overflow-hidden rounded-xl bg-black text-white",
                "lg:rounded-[20px]",
                className
            )}
            {...restProps}
        >
            {bgImageCover && <BgImageCover {...bgImageCover} />}

            {children}
        </Container>
    )
);
