import { IS_SSR } from "@Shared/config";

import { parseOrDefault } from ".";

const checkIsUnavailable = () => {
    if (IS_SSR) {
        return true;
    }

    return false;
};

const get = (key: string, defaultValue?: object | null | undefined) => {
    if (checkIsUnavailable()) {
        return defaultValue;
    }

    return parseOrDefault(window.localStorage.getItem(key), defaultValue);
};

const has = (key: string): boolean => {
    return Boolean(get(key, null));
};

const set = (key: string, value: string | object | null | undefined): boolean => {
    if (checkIsUnavailable()) {
        return false;
    }

    window.localStorage.setItem(key, JSON.stringify(value));

    return true;
};

const reset = (key: string): boolean => {
    return set(key, null);
};

const getOrSetDefault = (key: string, defaultValue?: string | object | null | undefined) => {
    if (has(key)) {
        return get(key);
    }

    set(key, defaultValue);

    return get(key);
};

export const storage = {
    get,
    has,
    set,
    reset,
    getOrSetDefault,
};
