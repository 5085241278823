import { IS_SSR } from "@Shared/config";

const SCROLL_LOCK_CLASSNAME = "is-scroll-locked";

let dataScrollLocks: Set<string>;

export const scrollLock = (state: boolean, name: string) => {
    if (IS_SSR) {
        return;
    }

    if (state) {
        if (typeof dataScrollLocks === "undefined") {
            dataScrollLocks = new Set();
        }

        dataScrollLocks.add(name);

        document.documentElement.classList.add(SCROLL_LOCK_CLASSNAME);
    } else {
        dataScrollLocks.delete(name);

        if (!dataScrollLocks.size) {
            document.documentElement.classList.remove(SCROLL_LOCK_CLASSNAME);
        }
    }
};
