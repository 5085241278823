import { forwardRef } from "react";

import { ElProps } from "@Shared/types";

export type LinkSimpleProps = ElProps<"a">;

export const LinkSimple = forwardRef<HTMLAnchorElement, LinkSimpleProps>(({ className, ...restProps }, ref) => {
    return (
        <a
            ref={ref}
            className={className}
            rel={restProps.target === "_blank" ? "noopener noreferrer nofollow" : undefined}
            {...restProps}
        />
    );
});

LinkSimple.displayName = "LinkSimple";
