import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

import styles from "./styles.module.scss";

export type WysiwygProps = ElProps<"div"> & {
    content: string;
};

export const Wysiwyg = forwardRef<HTMLDivElement, WysiwygProps>(({ className, content, ...restProps }, ref) => {
    return (
        <div
            ref={ref}
            className={twcx(styles.component, className)}
            dangerouslySetInnerHTML={{ __html: content }}
            {...restProps}
        />
    );
});

Wysiwyg.displayName = "Wysiwyg";
