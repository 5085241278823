import { getPopulateFields } from "@Shared/utils";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { Response, ResponseData, SingleCommon, SingleFooter, SingleHeader, SingleNavigation } from "../types";

export class CommonApi extends Repository {
    getCommonData = async (): Promise<SingleCommon> => {
        const result: Response<ResponseData<SingleCommon>> = await this.client.request.get(ENDPOINTS.common, {
            populate: `${getPopulateFields(["seo"])},contacts`,
        });

        return result?.data?.attributes ?? null;
    };

    getFooterData = async (): Promise<SingleFooter> => {
        const result: Response<ResponseData<SingleFooter>> = await this.client.request.get(ENDPOINTS.footer, {
            populate: `${getPopulateFields(["links"])},buttonContactUsImage`,
        });

        return result?.data?.attributes ?? null;
    };

    getHeaderData = async (): Promise<SingleHeader> => {
        const result: Response<ResponseData<SingleHeader>> = await this.client.request.get(ENDPOINTS.header, {
            populate: getPopulateFields(["links"]),
        });

        return result?.data?.attributes ?? null;
    };

    getNavigationData = async (): Promise<SingleNavigation> => {
        const result: Response<ResponseData<SingleNavigation>> = await this.client.request.get(ENDPOINTS.navigation, {
            populate: getPopulateFields(["links"]),
        });

        return result?.data?.attributes ?? null;
    };
}
