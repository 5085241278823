import { FC } from "react";

import { Text, TextProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type BadgeProps = TextProps;

export const Badge: FC<BadgeProps> = ({ className, ...restProps }) => {
    return (
        <Text
            className={twcx(
                "relative flex h-8 items-center justify-center truncate rounded-lg border border-white border-opacity-30 bg-white bg-opacity-10 px-3 lg:h-10 lg:px-5",
                {
                    "cursor-pointer": restProps.onClick,
                },
                className
            )}
            variant="p3"
            weight={700}
            color="green"
            align="center"
            uppercase
            {...restProps}
        />
    );
};
