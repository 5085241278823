"use client";

import { forwardRef, useMemo, useState } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";

import { Spinner } from "@Shared/ui";

import { ImageProps } from "./Image";

export type ImageWithLoaderProps = Pick<ImageProps, "classNames"> & NextImageProps;

export const ImageWithLoader = forwardRef<HTMLDivElement, ImageWithLoaderProps>(
    ({ classNames, src, ...restProps }, ref) => {
        const [isLoading, setIsLoading] = useState<boolean>(true);

        const memoImage = useMemo(
            () =>
                src ? (
                    <NextImage
                        src={src}
                        {...restProps}
                        onLoad={e => {
                            if (restProps.onLoad) {
                                restProps.onLoad(e);
                            }

                            setIsLoading(false);
                        }}
                        onError={e => {
                            if (restProps.onError) {
                                restProps.onError(e);
                            }

                            setIsLoading(false);
                        }}
                    />
                ) : null,
            [src]
        );

        if (!src) {
            return null;
        }

        return (
            <div ref={ref} className={classNames?.wrapper}>
                {isLoading && (
                    <div className={classNames?.loader}>
                        <Spinner className={classNames?.spinner} />
                    </div>
                )}

                {memoImage}
            </div>
        );
    }
);

ImageWithLoader.displayName = "ImageWithLoader";
