import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { Contacts, Socials } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type GroupContactsSocialsProps = ElProps<"div">;

export const GroupContactsSocials = forwardRef<HTMLDivElement, GroupContactsSocialsProps>(
    ({ className, ...restProps }, ref) => {
        return (
            <div
                ref={ref}
                className={twcx(
                    "flex flex-col gap-8 border-t border-t-white border-opacity-30 pt-6",
                    "lg:gap-10",
                    className
                )}
                {...restProps}
            >
                <Contacts />
                <Socials />
            </div>
        );
    }
);
