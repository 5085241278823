import { forwardRef } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import * as _ from "lodash-es";

import { Icon } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { ImageWithLoader } from "./ImageWithLoader";

type ImagePropsClassNames = {
    wrapper?: string;
    loader?: string;
    spinner?: string;
    icon?: string;
};

type ImagePropsSizes = {
    default?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
    "2xl"?: string;
    "3xl"?: string;
};

export type ImageProps = Omit<NextImageProps, "src" | "alt" | "sizes"> & {
    classNames?: ImagePropsClassNames;
    src?: NextImageProps["src"] | null;
    alt?: string;
    sizes?: ImagePropsSizes;
    withLoader?: boolean;
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(
    ({ className, classNames, src, alt = "", sizes, withLoader, ...restProps }, ref) => {
        const getSizes = () => {
            const sizesArr = [];

            if (sizes?.default) {
                sizesArr.push(sizes.default);
            }

            if (sizes?.["3xl"]) {
                sizesArr.push(`(min-width: 1920px) ${sizes["3xl"]}`);
            }

            if (sizes?.["2xl"]) {
                sizesArr.push(`(min-width: 1440px) ${sizes["2xl"]}`);
            }

            if (sizes?.xl) {
                sizesArr.push(`(min-width: 1280px) ${sizes.xl}`);
            }

            if (sizes?.lg) {
                sizesArr.push(`(min-width: 1025px) ${sizes.lg}`);
            }

            if (sizes?.md) {
                sizesArr.push(`(min-width: 768px) ${sizes.md}`);
            }

            if (sizes?.sm) {
                sizesArr.push(`(min-width: 640px) ${sizes.sm}`);
            }

            if (_.isEmpty(sizesArr)) {
                return;
            }

            return sizesArr.join(", ");
        };

        if (!src) {
            return (
                <div
                    ref={ref}
                    className={twcx(
                        "flex h-full w-full items-center justify-center overflow-hidden bg-black text-white",
                        className
                    )}
                >
                    <Icon className={twcx("size-3/4", classNames?.icon)} k="image" />
                </div>
            );
        }

        const imageProps = {
            src,
            alt,
            sizes: getSizes(),
            quality: 90,
            ...restProps,
        };

        return withLoader ? (
            <ImageWithLoader
                ref={ref}
                className={className}
                classNames={{
                    wrapper: twcx("relative z-0 h-full w-full", classNames?.wrapper),
                    loader: twcx("absolute inset-0 z-10", classNames?.loader),
                    spinner: classNames?.spinner,
                }}
                {...imageProps}
            />
        ) : (
            <NextImage ref={ref} className={className} {...imageProps} />
        );
    }
);

Image.displayName = "Image";
