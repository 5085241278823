"use client";

import { forwardRef } from "react";
import { useFormStatus } from "react-dom";

import { Button, ButtonProps } from "./Button";

export type ButtonSubmitProps = ButtonProps;

export const ButtonSubmit = forwardRef<HTMLButtonElement, ButtonSubmitProps>((props, ref) => {
    const { pending } = useFormStatus();

    return <Button ref={ref} type="submit" disabled={pending} loading={pending} {...props} />;
});

ButtonSubmit.displayName = "ButtonSubmit";
