import { FC } from "react";
import { cva, VariantProps } from "class-variance-authority";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

import { IconsCollection, IconsKeys } from "./collection";
import styles from "./styles.module.scss";

type IconVariantsProps = VariantProps<typeof iconVariants>;

const iconVariants = cva([styles.component, "block h-[1em] w-[1em] max-w-none text-[1em] leading-none"], {
    variants: {
        size: {
            sm: "text-[20px]",
            md: "text-[24px]",
        },
    },
    defaultVariants: {
        size: "md",
    },
});

const icon = (variants: IconVariantsProps) => iconVariants(variants);

export type IconProps = ElProps<"svg", keyof IconVariantsProps> &
    IconVariantsProps & {
        k: IconsKeys;
    };

export const Icon: FC<IconProps> = ({ className, k, ...restProps }) => {
    const Component = IconsCollection[k];

    if (!Component) {
        return null;
    }

    return <Component className={twcx(icon(restProps), className)} {...restProps} />;
};
