import { forwardRef } from "react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

type TextVariants = VariantProps<typeof textVariants>;

const textVariants = cva("", {
    variants: {
        variant: {
            max: "text-max-d lg:text-max",
            h1: "text-h1-d lg:text-h1",
            h1Sm: "text-h1-sm-d lg:text-h1",
            h2: "text-h2-d lg:text-h2",
            h2Lg: "text-h2-lg-d lg:text-h2-lg",
            h3: "text-h3-d lg:text-h3",
            h4: "text-h4-d lg:text-h4",
            h5: "text-h5-d lg:text-h5",
            p1: "text-p1-d lg:text-p1",
            p2: "text-p2-d lg:text-p2",
            p3: "text-p3-d lg:text-p3",
        },
        weight: {
            400: "font-normal",
            500: "font-medium",
            600: "font-semibold",
            700: "font-bold",
        },
        uppercase: {
            true: "uppercase",
        },
        align: {
            left: "text-left",
            center: "text-center",
            right: "text-right",
        },
        color: {
            transparent: "text-transparent",
            current: "text-current",
            "total-white": "text-total-white",
            white: "text-white",
            "total-black": "text-total-black",
            black: "text-black",
            "gray-01": "text-gray-01",
            "gray-02": "text-gray-02",
            "gray-03": "text-gray-03",
            "gray-04": "text-gray-04",
            "gray-05": "text-gray-05",
            "bg-blue": "text-bg-blue",
            "blue-bright": "text-blue-bright",
            "blue-light": "text-blue-light",
            green: "text-green",
            red: "text-red",
        },
    },
    defaultVariants: {
        variant: "p1",
    },
});

const text = (variants: TextVariants) => textVariants(variants);

export type TextProps = ElProps<"div", keyof TextVariants> &
    TextVariants & {
        as?: "div" | "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    };

export const Text = forwardRef<HTMLDivElement, TextProps>(
    ({ as = "div", className, variant, weight, uppercase, align, color, ...restProps }, ref) => {
        const Component = as;

        return (
            <Component
                ref={ref}
                className={twcx(text({ variant, weight, uppercase, align, color }), className)}
                {...restProps}
            />
        );
    }
);

Text.displayName = "Text";
