import { forwardRef } from "react";

import { ComponentSharedContacts, getCommonData } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { Link, Text, TextIcon } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type ContactsListModel = {
    id: keyof ComponentSharedContacts;
    name: string;
}[];

const CONTACTS_LIST: ContactsListModel = [
    { id: "telegram", name: "Telegram" },
    { id: "whatsapp", name: "WhatsApp" },
    { id: "vkontakte", name: "VKontakte" },
    { id: "facebook", name: "Facebook" },
    { id: "twitter", name: "Twitter (X)" },
    { id: "instagram", name: "Instagram" },
    { id: "behance", name: "Behance" },
    { id: "linkedin", name: "LinkedIn" },
];

type ContactsPropsClassNames = {
    topLinks?: string;
    linkTop?: string;
    bottomLinks?: string;
    linkBottom?: string;
    linkBottomIcon?: string;
};

export type ContactsProps = ElProps<"div"> & {
    classNames?: ContactsPropsClassNames;
};

export const Contacts = forwardRef<HTMLDivElement, ContactsProps>(
    async ({ className, classNames, ...restProps }, ref) => {
        const {
            common: { contacts },
        } = await getCommonData();

        if (!contacts) {
            return;
        }

        const linksCommonClassName = "lg:transition-colors lg:hover:text-blue-light";

        return (
            <div ref={ref} className={twcx("flex flex-col gap-4", "lg:gap-6", className)} {...restProps}>
                {(contacts.email || contacts.phone) && (
                    <Text
                        className={twcx("flex flex-col items-start gap-3", "lg:gap-6", classNames?.topLinks)}
                        variant="h4"
                        weight={600}
                    >
                        {contacts.email && (
                            <Link
                                className={twcx(linksCommonClassName, classNames?.linkTop)}
                                email={contacts.email}
                                target="_blank"
                            />
                        )}

                        {contacts.phone && (
                            <Link
                                className={twcx(linksCommonClassName, classNames?.linkTop)}
                                phone={contacts.phone}
                                target="_blank"
                            />
                        )}
                    </Text>
                )}

                {!!CONTACTS_LIST.length && (
                    <Text
                        className={twcx("flex flex-wrap gap-3", "lg:gap-6", classNames?.bottomLinks)}
                        variant="p2"
                        weight={500}
                    >
                        {CONTACTS_LIST.map(contact =>
                            contacts[contact.id] ? (
                                <Link
                                    key={contact.id}
                                    className={twcx(linksCommonClassName, classNames?.linkBottom)}
                                    href={contacts[contact.id]}
                                    target="_blank"
                                >
                                    <TextIcon
                                        classNames={{
                                            icon: twcx("text-gray-05 max-lg:text-[16px]", classNames?.linkBottomIcon),
                                        }}
                                        icon="arrowTopRight"
                                        iconSize="sm"
                                        iconPosition="right"
                                    >
                                        {contact.name}
                                    </TextIcon>
                                </Link>
                            ) : null
                        )}
                    </Text>
                )}
            </div>
        );
    }
);
