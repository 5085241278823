import { getPopulateFields } from "@Shared/utils";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionArticle, ResponseCollection, ResponseData } from "../types";

export class ArticlesApi extends Repository {
    getArticlesData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionArticle>> = await this.client.request.get(
            ENDPOINTS.articles,
            {
                sort: ["sort:asc", "id:desc"],
                fields: ["slug", "sort", "title", "date", "readingTime", "publishedAt"],
                populate: "imagePreview,category",
                ...params,
            }
        );

        return {
            data: result?.data ?? [],
            pagination: result?.meta?.pagination ?? null,
        };
    };

    getArticleData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionArticle>> = await this.client.request.get(
            ENDPOINTS.articles,
            {
                filters: {
                    slug,
                },
                populate: `${getPopulateFields(["seo"])},imageFull,category`,
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };
}
