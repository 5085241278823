import { forwardRef } from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

import { ElProps } from "@Shared/types";
import { phoneNormalize, phonePrint, twcx } from "@Shared/utils";

import { LinkSimple } from "./LinkSimple";

export type LinkProps = ElProps<"a"> &
    Omit<NextLinkProps, "href"> & {
        simple?: boolean;
        phone?: string;
        email?: string;
    };

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ className, href, simple, phone, email, children, ...restProps }, ref) => {
        let to = href;
        let content = children;

        if (phone) {
            to = `tel:${phoneNormalize(phone)}`;
            content = phonePrint(phoneNormalize(phone));
        } else if (email) {
            to = `mailto:${email}`;
            content = email;
        }

        const commonLinkProps = {
            ref,
            className: twcx(
                {
                    "text-nowrap": phone || email,
                },
                className
            ),
            href: to ?? "",
            ...restProps,
        };

        if (simple || phone || email) {
            return <LinkSimple {...commonLinkProps}>{content}</LinkSimple>;
        }

        return <NextLink {...commonLinkProps}>{content}</NextLink>;
    }
);

Link.displayName = "Link";
