"use client";

import { FocusEvent, forwardRef, ReactNode, useEffect, useState } from "react";

import { ElProps } from "@Shared/types";
import { Icon, IconProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type InputPropsClassNames = {
    label?: string;
    wrapper?: string;
    input?: string;
    icon?: string;
    buttonClear?: string;
    error?: string;
};

export type InputProps = ElProps<"input"> &
    ElProps<"textarea"> & {
        classNames?: InputPropsClassNames;
        label?: ReactNode;
        error?: boolean | string;
        icon?: IconProps["k"];
        iconSize?: IconProps["size"];
        buttonClear?: boolean;
        isTextarea?: boolean;
        value?: string;
        setValue?: (value: string) => void;
    };

let blurHideClearButtonTimeout: ReturnType<typeof setTimeout>;

export const Input = forwardRef<HTMLInputElement & HTMLTextAreaElement, InputProps>(
    (
        {
            className,
            classNames,
            label,
            error,
            icon,
            iconSize,
            buttonClear = true,
            isTextarea,
            value,
            setValue,
            onFocus,
            onBlur,
            ...restProps
        },
        ref
    ) => {
        const [isButtonClearVisible, setIsButtonClearVisible] = useState<boolean>(false);

        const handleInputFocus = (e: FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
            clearTimeout(blurHideClearButtonTimeout);

            setIsButtonClearVisible(buttonClear && !!value?.length);

            onFocus?.(e);
        };

        const handleInputBlur = (e: FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
            clearTimeout(blurHideClearButtonTimeout);

            blurHideClearButtonTimeout = setTimeout(() => setIsButtonClearVisible(false), 100);

            onBlur?.(e);
        };

        const handleButtonClearClick = () => setValue?.("");

        const inputProps = {
            ref,
            className: twcx(
                "flex h-12 w-full resize-none appearance-none items-center truncate rounded-lg border border-gray-01 bg-gray-01 px-4 py-0 text-p2-d font-medium text-white caret-blue-light shadow-none outline-none transition-colors",
                "placeholder:text-gray-04 placeholder:opacity-100",
                "focus:border-white focus:border-opacity-30",
                "lg:h-[60px] lg:rounded-xl lg:text-p2",
                "lg:[&:not(:disabled)]:hover:border-white lg:[&:not(:disabled)]:hover:border-opacity-30",
                {
                    "h-[180px] whitespace-normal py-5 lg:h-[180px]": isTextarea,
                    "pr-12": icon || isButtonClearVisible,
                    "!border-red": error,
                    "cursor-custom-forbidden opacity-60": restProps.disabled,
                },
                classNames?.input
            ),
            ...restProps,
            value: value,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
        };

        useEffect(() => {
            setIsButtonClearVisible(buttonClear && !!value?.length);
        }, [value]);

        return (
            <div className={twcx("relative flex flex-col items-start gap-3", className)}>
                {label && (
                    <label
                        className={twcx("cursor-pointer text-p2-d font-semibold lg:text-p2", classNames?.label)}
                        htmlFor={restProps.id}
                    >
                        {label}
                    </label>
                )}

                <div className={twcx("relative w-full", classNames?.wrapper)}>
                    {isTextarea ? <textarea {...inputProps} /> : <input {...inputProps} />}

                    {icon && !isButtonClearVisible && (
                        <Icon
                            className={twcx(
                                "pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 text-gray-04",
                                {
                                    "top-3 translate-y-0 lg:top-5": isTextarea,
                                },
                                classNames?.icon
                            )}
                            k={icon}
                            size={iconSize}
                        />
                    )}

                    {isButtonClearVisible && (
                        <button
                            className={twcx(
                                "absolute right-2 top-1/2 flex size-8 -translate-y-1/2 items-center justify-center text-gray-04 transition-colors lg:enabled:hover:text-blue-light",
                                {
                                    "top-2 translate-y-0 lg:top-4": isTextarea,
                                },
                                classNames?.buttonClear
                            )}
                            type="button"
                            aria-label="Clear field"
                            onClick={handleButtonClearClick}
                        >
                            <Icon className="text-[16px]" k="closeCircle" />
                        </button>
                    )}
                </div>

                {typeof error === "string" && (
                    <label
                        className={twcx("cursor-pointer text-p2-d font-medium text-red lg:text-p2", classNames?.error)}
                        htmlFor={restProps.id}
                    >
                        {error}
                    </label>
                )}
            </div>
        );
    }
);

Input.displayName = "Input";
