import urlJoin from "url-join";

import { API_URL } from "@Shared/config";

import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { TelegramSendMessageDTO, TelegramSendMessageResponse } from "../types";

export class TelegramApi extends Repository {
    sendTelegramMessage = async (message: TelegramSendMessageDTO["message"]) => {
        const result: TelegramSendMessageResponse = await this.client.request.post(
            "",
            {
                message,
            } as TelegramSendMessageDTO,
            undefined,
            {
                url: urlJoin(API_URL.replace("/api", ""), ENDPOINTS.telegramSendMessage),
            }
        );

        return result;
    };
}
