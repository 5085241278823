type getPopulateFieldsProps = (string | string[])[];

const fields: { [key: string]: any } = {
    seo: (name: string = "seo") => `${name},${name}.metaImage,${name}.metaSocial,${name}.metaSocial.image`,
    video: (name: string = "video") => `${name},${name}.file,${name}.poster`,
    links: (name: string = "links") => `${name},${name}.link`,
};

const getField = (name: string | string[]) => {
    if (typeof name === "string") {
        return fields[name] ? fields[name]() : "";
    } else {
        return fields[name[0]] ? fields[name[0]](name[1]) : "";
    }
};

export const getPopulateFields = (fields: getPopulateFieldsProps) => {
    let populate = "";

    fields.forEach((field, fieldIndex) => {
        populate += `${fieldIndex ? "," : ""}${getField(field)}`;
    });

    return populate;
};
