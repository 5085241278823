import { FC } from "react";

// import { isbot } from "isbot";

import { YandexMetrika } from "./YandexMetrika";

export const Analytics: FC = () => {
    // if (isbot(userAgent)) {
    //     return;
    // }

    return (
        <>
            <YandexMetrika />
        </>
    );
};
