import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionModal, ResponseCollection, ResponseData } from "../types";

export class ModalsApi extends Repository {
    getModalData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionModal>> = await this.client.request.get(
            ENDPOINTS.modals,
            {
                filters: {
                    slug,
                },
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };
}
