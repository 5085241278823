import { forwardRef } from "react";

import { getCommonData } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { Button, IconProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

const SOCIAL_ICONS: Record<string, IconProps["k"]> = {
    behance: "behance",
    telegram: "telegram",
    linkedin: "linkedIn",
    instagram: "instagram",
};

export type SocialsProps = ElProps<"div">;

export const Socials = forwardRef<HTMLDivElement, SocialsProps>(async ({ className, ...restProps }, ref) => {
    const { socials } = await getCommonData();

    if (!socials.length) {
        return;
    }

    return (
        <div
            ref={ref}
            className={twcx("grid grid-cols-4 gap-1", "sm:flex sm:flex-wrap", "lg:gap-2", className)}
            {...restProps}
        >
            {socials.map(({ attributes: { slug, name, link } }) => (
                <Button
                    key={slug}
                    className={twcx("h-12 px-4 max-lg:rounded-lg", "sm:px-[33px]", "lg:h-[68px]")}
                    classNames={{ textIconIcon: "lg:text-[32px]" }}
                    variant="outline"
                    icon={SOCIAL_ICONS[slug]}
                    href={link}
                    target="_blank"
                    aria-label={name}
                />
            ))}
        </div>
    );
});
