import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

export type ContainerProps = ElProps<"div"> & {
    as?: "div" | "span" | "main" | "header" | "footer" | "section" | "article" | "nav";
    grid?: boolean;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ className, as = "div", grid, ...restProps }, ref) => {
        const Component = as ?? "div";

        return (
            <Component
                ref={ref}
                className={twcx(
                    "w-full px-3",
                    "md:px-4",
                    "lg:px-11",
                    {
                        "grid grid-cols-3 gap-3": grid,
                        "md:grid-cols-4": grid,
                        "lg:grid-cols-12 lg:gap-4": grid,
                    },
                    className
                )}
                {...restProps}
            />
        );
    }
);
