"use client";

import { ForwardedRef, forwardRef, ReactNode, useState } from "react";
import { useUpdateEffect } from "react-use";
import { cva, VariantProps } from "class-variance-authority";

import { ElProps } from "@Shared/types";
import { Link, LinkProps, Spinner, TextIcon, TextIconProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type ButtonVariants = VariantProps<typeof buttonVariants>;

const buttonVariants = cva(
    [
        "relative z-0 inline-flex cursor-pointer appearance-none items-center justify-center gap-1 overflow-hidden whitespace-nowrap rounded-none border-0 p-0 text-center font-bold uppercase leading-none tracking-normal no-underline shadow-none transition-all",
        "disabled:cursor-custom-forbidden disabled:select-none disabled:opacity-60",
    ],
    {
        variants: {
            variant: {
                primary: [
                    "h-[60px] justify-between gap-2 rounded-xl bg-white pl-[15px] pr-3 text-left text-p1-d text-black",
                    "lg:h-[68px] lg:text-p1",
                    "lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
                secondary: [
                    "h-9 rounded-lg bg-white px-3 text-p2-d text-black",
                    "lg:h-10 lg:rounded-xl lg:px-4 lg:text-p2",
                    "lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
                tertiary: [
                    "h-[60px] rounded-xl bg-blue-bright px-4 text-p1-d text-white",
                    "lg:h-[68px] lg:text-p1",
                    "lg:[&:not(:disabled)]:hover:bg-white lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
                outline: [
                    "h-[60px] gap-2 rounded-2xl border border-white border-opacity-30 bg-transparent px-10 text-p1-d text-white",
                    "lg:h-[72px] lg:text-p1",
                    "lg:[&:not(:disabled)]:hover:border-white lg:[&:not(:disabled)]:hover:bg-white lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
                outlineDark: [
                    "h-[60px] gap-2 rounded-2xl border border-gray-02 bg-transparent px-10 text-p1-d text-black",
                    "lg:h-[72px] lg:text-p1",
                    "lg:[&:not(:disabled)]:hover:border-blue-bright lg:[&:not(:disabled)]:hover:bg-blue-bright lg:[&:not(:disabled)]:hover:text-white",
                ],
                underline: [
                    "h-[37px] border-b border-b-current text-p1-d text-white",
                    "lg:h-[42px] lg:text-p1",
                    "lg:[&:not(:disabled)]:hover:text-blue-light",
                ],
                tab: [
                    "h-12 rounded-xl border border-white border-opacity-30 px-6 text-p2-d font-semibold normal-case text-gray-05",
                    "lg:h-[60px] lg:text-p2",
                    "lg:[&:not(:disabled)]:hover:border-white lg:[&:not(:disabled)]:hover:text-white",
                ],
                tabDark: [
                    "h-12 rounded-xl border border-gray-04 px-6 text-p2-d font-semibold normal-case text-black",
                    "lg:h-[60px] lg:text-p2",
                    "lg:[&:not(:disabled)]:hover:border-blue-bright lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
                dropdown: [
                    "h-10 rounded-lg bg-white px-7 text-p2-d text-black",
                    "lg:text-p2",
                    "lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
            },
            block: {
                true: "flex w-full",
            },
            disabled: {
                true: "cursor-custom-forbidden select-none opacity-60",
            },
            loading: {
                true: "cursor-wait select-none opacity-60",
            },
            active: {
                true: "",
            },
        },
        compoundVariants: [
            {
                variant: "tab",
                active: true,
                class: [
                    "bg-white text-blue-bright",
                    "lg:[&:not(:disabled)]:hover:bg-white lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
            },
            {
                variant: "tabDark",
                active: true,
                class: [
                    "border-blue-bright bg-blue-bright text-white",
                    "lg:[&:not(:disabled)]:hover:bg-transparent lg:[&:not(:disabled)]:hover:text-blue-bright",
                ],
            },
        ],
        defaultVariants: {
            variant: "primary",
        },
    }
);

const button = (variants: ButtonVariants) => buttonVariants(variants);

type ButtonPropsClassNames = {
    spinner?: string;
    textIconContainer?: string;
    textIconIcon?: string;
    textIconWrapper?: string;
    textIconText?: string;
};

export type ButtonProps = ElProps<"button", keyof ButtonVariants & LinkProps> &
    ButtonVariants &
    LinkProps & {
        classNames?: ButtonPropsClassNames;
        icon?: TextIconProps["icon"];
        iconSize?: TextIconProps["iconSize"];
        iconPosition?: TextIconProps["iconPosition"];
        enableDelay?: number;
        loadingContent?: ReactNode;
    };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            classNames,
            icon,
            iconSize,
            iconPosition = "right",
            enableDelay,
            loadingContent,
            type = "button",
            variant,
            block,
            loading,
            disabled,
            active,
            children,
            ...restProps
        },
        ref
    ) => {
        const [isDisabled, setIsDisabled] = useState<boolean>(!!disabled);

        const propDisabled = enableDelay ? isDisabled : disabled;
        const isVariantPrimary = typeof variant === "undefined" || variant === "primary";

        const commonProps = {
            ref,
            className: twcx(
                button({ variant, block, disabled: propDisabled, loading, active, ...restProps }),
                className
            ),
            disabled: propDisabled,
            ...restProps,
        };

        let content = children;

        if (loading) {
            content = loadingContent ?? <Spinner className={twcx(classNames?.spinner)} />;
        } else if (icon) {
            content = (
                <TextIcon
                    className={twcx(
                        "gap-[inherit]",
                        {
                            "w-full justify-between": isVariantPrimary,
                        },
                        classNames?.textIconContainer
                    )}
                    classNames={{
                        icon: twcx(
                            {
                                "max-lg:text-[20px]": variant === "underline",
                            },
                            classNames?.textIconIcon
                        ),
                        iconWrapper: twcx(
                            {
                                "flex size-10 shrink-0 items-center justify-center rounded-full bg-blue-bright text-white lg:size-11":
                                    isVariantPrimary,
                            },
                            classNames?.textIconWrapper
                        ),
                        text: classNames?.textIconText,
                    }}
                    icon={icon}
                    iconSize={iconSize}
                    iconPosition={iconPosition}
                    iconWrapper={isVariantPrimary}
                >
                    {children}
                </TextIcon>
            );
        }

        useUpdateEffect(() => {
            let enableDelayTimeout: ReturnType<typeof setTimeout>;

            if (enableDelay) {
                if (disabled) {
                    setIsDisabled(true);
                } else {
                    enableDelayTimeout = setTimeout(() => {
                        setIsDisabled(false);
                    }, enableDelay);
                }
            }

            return () => clearTimeout(enableDelayTimeout);
        }, [disabled]);

        if (restProps.href) {
            return (
                <Link {...commonProps} ref={ref as ForwardedRef<HTMLAnchorElement>}>
                    {content}
                </Link>
            );
        }

        return (
            <button type={type} {...commonProps}>
                {content}
            </button>
        );
    }
);

Button.displayName = "Button";
