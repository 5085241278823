import { sendTelegramMessage as apiSendTelegramMessage } from "@Shared/api";

import { getTextWithReplace } from "./text";

type SendFormResultToTelegramProps = {
    message?: string;
    formData?: FormData;
};

export const sendFormResultToTelegram = ({ message, formData }: SendFormResultToTelegramProps) => {
    let msg = message?.trim();

    if (!msg) {
        return;
    }

    if (formData) {
        Object.entries(Object.fromEntries(formData)).forEach(([key, value]) => {
            msg = getTextWithReplace({
                text: String(msg),
                from: `{{${key}}}`,
                to: value && String(value).length ? String(value) : "🚫",
            });
        });
    }

    apiSendTelegramMessage(msg);
};
