import { FC, Fragment } from "react";

import { ElProps } from "@Shared/types";
import { Icon, IconProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type TextIconPropsClassNames = {
    icon?: string;
    iconWrapper?: string;
    text?: string;
};

export type TextIconProps = ElProps<"span"> & {
    classNames?: TextIconPropsClassNames;
    icon: IconProps["k"];
    iconSize?: IconProps["size"];
    iconPosition?: "left" | "right";
    iconWrapper?: boolean;
};

export const TextIcon: FC<TextIconProps> = ({
    className,
    classNames,
    icon,
    iconSize,
    iconPosition = "left",
    iconWrapper,
    children,
    ...restProps
}) => {
    const IconWrapper = iconWrapper ? "span" : Fragment;

    return (
        <span
            className={twcx(
                "flex items-center gap-1",
                {
                    "flex-row-reverse": iconPosition === "right",
                },
                className
            )}
            {...restProps}
        >
            <IconWrapper {...(iconWrapper ? { className: classNames?.iconWrapper } : {})}>
                <Icon className={twcx("shrink-0", classNames?.icon)} k={icon} size={iconSize} />
            </IconWrapper>

            {children && <span className={classNames?.text}>{children}</span>}
        </span>
    );
};
