import twConfig from "@/tailwind.config";
import type { ClassValue } from "clsx";
import cx from "clsx";
import * as _ from "lodash-es";
import { extendTailwindMerge } from "tailwind-merge";
import resolveTailwindConfig from "tailwindcss/resolveConfig";

export const twTheme = resolveTailwindConfig(twConfig).theme;

export const twMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            "font-size": Object.keys(twTheme?.fontSize || {}).map(key => `text-${key}`),
            "text-color": Object.keys(twTheme?.colors || {}).map(key => `text-${key}`),
        },
    },
});

export const twcx = (...args: ClassValue[]) => twMerge(cx(args));

export const twGetThemeProp = (name: keyof typeof twTheme, path: string) => {
    return String(_.get(twTheme?.[name] || {}, path)) ?? undefined;
};
