import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionForm, ResponseCollection, ResponseData } from "../types";

export class FormsApi extends Repository {
    getFormData = async (slug: string, params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionForm>> = await this.client.request.get(
            ENDPOINTS.forms,
            {
                filters: {
                    slug,
                },
                populate: "fields",
                ...params,
            }
        );

        return result?.data?.[0]?.attributes ?? null;
    };

    sendFormData = async (slug: string, data: object) => {
        const result = await this.client.request.post(ENDPOINTS.formsSend, {
            formName: slug,
            formData: data,
        });

        return result;
    };
}
