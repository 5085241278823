import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

export type WrapperProps = ElProps<"div"> & {
    as?: "div" | "span" | "main" | "header" | "footer" | "section" | "article" | "nav";
};

export const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(({ className, as = "div", ...restProps }, ref) => {
    const Component = as ?? "div";

    return <Component ref={ref} className={twcx("w-full p-2", "md:p-4", "lg:p-3", className)} {...restProps} />;
});
